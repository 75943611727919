import { Link } from 'gatsby';
import { Row, Col, Container, Table } from 'react-bootstrap';
import * as React from 'react';
export default {
  Link,
  Row,
  Col,
  Container,
  Table,
  React
};